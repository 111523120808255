import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import { ReactComponent as Loop } from '@images/svgs/loop.svg';
import { ReactComponent as Quick } from '@images/svgs/quick.svg';
import { ReactComponent as Happy } from '@images/svgs/happy.svg';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Box>
            <Grid>
              <Art>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="uim-primary" d="M21,20.794H3a1,1,0,0,1-.86621-1.5l9-15.5879a1.04009,1.04009,0,0,1,1.73242,0l9,15.5879A1,1,0,0,1,21,20.794Z" fill="#1c1c1c"></path></svg>
              </Art>
              <div>
                <h2>Advise</h2>
                <p>
                  As a team previously in charge of running internet businesses and operating studios we've amassed vast experience building products and services.
                </p>
                <p>
                  We continue to work with companies providing advice on everything from branding, design, development, operations to strategy and pricing.
                </p>
              </div>
            </Grid>
            <Grid>
              <Art>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="uim-primary" d="M16.5,20.794h-9a.99983.99983,0,0,1-.86621-.5L2.13379,12.5a1.002,1.002,0,0,1,0-1l4.5-7.79395a.99983.99983,0,0,1,.86621-.5h9a.99987.99987,0,0,1,.86621.5l4.5,7.794a1.002,1.002,0,0,1,0,1l-4.5,7.79395A.99987.99987,0,0,1,16.5,20.794Z" fill="#B52908"></path></svg>
              </Art>
              <div>
                <h2>Build</h2>
                <p>
                  Not only do we provide advice, but we also build as we are builders from day one. We are capable of going from a rough sketch to a real shipped product.
                </p>
                <p>
                  Our team is highly specialized in design and software development, allowing you to turn great new ideas into amazing new products and services.
                </p>
                <Box2>
                  <IconText>
                    <Icon>
                      {Happy()}
                    </Icon>
                    <SmallText>
                      We keep things simple, help you stay lean and have your product do one thing well.
                    </SmallText>
                  </IconText>
                  <IconText>
                    <Icon>
                       {Quick()}
                    </Icon>
                    <SmallText>
                      Quickly build a minimum viable product and go to market with a solid foundation.
                    </SmallText>
                  </IconText>
                  <IconText>
                    <Icon>
                      {Loop()}
                    </Icon>
                    <SmallText>
                      We don’t make you shiny expensive decks. We build, prototype, test and repeat.
                    </SmallText>
                  </IconText>
                </Box2>
              </div>
            </Grid>
            <Grid>
              <Art>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" fill="#f4c13c"/></svg>
              </Art>
              <div>
                <h2>Invest</h2>
                <p>
                  We are early stage investors in deeply mission-driven teams with big ambitions anywhere in the world, solving hard problems with technology and building great businesses from it.
                </p>
              </div>
            </Grid>
            <h3>
              How can we help you?
            </h3>
            <p>
              <StyledExternalLink href="mailto:hello@yaminote.com?subject=Let's chat">
                Let's chat &nbsp;&#x2794;
              </StyledExternalLink>
            </p>
          </Box>
        </Container>
      </Section>
    )}
  />
);

const Box = styled.figure`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin-top: -8px;

  h3 {
    margin-top: 64px;
    font-family: 'IBM Plex Mono', 'IBM Plex Sans', sans-serif;
    margin-bottom: 24px;
    font-size: 28px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {

    margin-top: -24px;

    h3 {
      margin-top: 24px;
      font-size: 20px;
    }
  }
`;

const Box2 = styled.figure`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-items: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 0;
  }
`;

const IconText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin-top: 16px;
`;

const Icon = styled.div`
  margin: 0;
  padding-right: 32px;
`;

const SmallText = styled.div`
  font-size: 16px;
  max-width: 320px;
  font-weight: 400;
  color: #1c1c1c;
  line-height: 150%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 12px;

  }
`;

const Grid = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  align-items: flex-start;
  justify-items: center;
  margin-bottom: 48px;
  max-width: 520px;

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    color: #7D8386;
    line-height: 150%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    flex-direction: column;
    text-align: left;
    margin-bottom: 96px;
    justify-items: left;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {

    margin-bottom: 60px;

    p {
      font-size: 16px;
    }

  }
`;

const Art = styled.figure`
  margin: 0;
  padding-right: 32px;
  width: 160px;
  margin-top: 8px;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 0;
    width: 80px;
    margin-top: 16px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: #7D8386;
  text-decoration: none;
  font-size: 24px;
  border-bottom: 4px dotted #f4c13c;
  text-decoration: none;

  &:hover {
    color: #f4c13c;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 20px;
  }
`;

export default About;
