import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';

import { ReactComponent as Logo } from '@images/brand/logo.svg';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ship" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Flex>
            <Box>
              <Brand>
                {Logo()}
              </Brand>
              <Text>
                <h3>
                  <span>Yaminote</span> advises, builds, and invests in companies with contrarian worldviews.
                </h3>
                <h4>
                  Our people and partners are experienced at helping turbocharge growing startups through ingenious design and transforming disruptive ideas into powerful businesses.
                </h4>
              </Text>
            </Box>
          </Flex>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: #F9F8F8;
  padding: 96px 0px 64px 0px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 96px 24px 64px 24px;
  }
`;

const Flex = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Box = styled.header`
  margin: 0;
`;

const Brand = styled.header`
  margin: 0;
`;

const Text = styled.div`
  color: ${props => props.theme.color.black.regular};
  margin-top: 32px;
  max-width: 560px;

  h3 {
    span {
      font-family: 'IBM Plex Mono', 'IBM Plex Sans', sans-serif;
      border-bottom: 4px dotted #f4c13c;
      text-decoration: none;
    }
  }

  h4 {
    margin-top: 16px;
    font-weight: 400;
    color: #7D8386;
    line-height: 150%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 16px;
    }

  }
`;

export default Header;
